import React from "react";
import {Link} from "gatsby";

function ModalSuccess({show, onClose}) {
    if (!show) {
        return null;
    }


    return (
        <div className="modal-overlay">
            <div className="modal">
                <div className="modal-content">
                    <img style={{paddingBottom: 20}} src={require('../assets/img/success.svg')} width={100}
                         height='auto'/>
                    <h1 className="services_section_1__title" style={{color: '#000000'}}>Mensaje enviado con éxito</h1>
                    <p className="services_section_1__paragraph">Gracias por ponerte en contacto con nosotros, te
                        responderemos lo antes posible</p>
                    <Link to="/" className="button">Aceptar</Link>
                </div>
            </div>
        </div>
    )
}

export default ModalSuccess;
