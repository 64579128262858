import React, {useState} from "react";

import Layout from "../components/Layout";

import ModalSuccess from '../components/ModalSuccess';
import ModalFail from '../components/ModalFail';

export default function Contact() {
    //Hooks
    const [isOpenSuccess, setIsOpenSuccess] = useState(false);
    const [isOpenFail, setIsOpenFail] = useState(false);


    const showModalSuccess = () => {
        setIsOpenSuccess(!isOpenSuccess);
    }

    const showModalFail = () => {
        setIsOpenFail(!isOpenFail);
    }

    const submitForm = (ev) => {
        ev.preventDefault();
        const form = ev.target;
        const data = new FormData(form);
        const xhr = new XMLHttpRequest();
        xhr.open(form.method, form.action);
        xhr.setRequestHeader("Accept", "application/json");
        xhr.onreadystatechange = () => {
            if (xhr.readyState !== XMLHttpRequest.DONE) return;
            if (xhr.status === 200) {
                form.reset();
                showModalSuccess();
            } else {
                showModalFail();
            }
        };
        xhr.send(data);
    }

    return (
        <Layout>
            <div id="main">
                <ModalSuccess show={isOpenSuccess}
                              onClose={showModalSuccess}/>
                <ModalFail show={isOpenFail}
                           onClose={showModalFail}/>
                <div className="contact_section_banner">
                    <div className="contact_banner">
                        <h1>Contacto</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                        <h1 className="services_section_1__title" style={{fontWeight: 'bold'}}>Estamos aquí por tí</h1>
                        <p className="services_section_1__paragraph">¿Tengo una pregunta? ¿Necesitas información?,
                            complete el formulario para informarnos cómo
                            podemos ayudarlo. ¡Estamos emocionados de saber de usted!</p>
                    </div>
                    <section className="card-contact">
                        <article>
                            <form onSubmit={submitForm} action="https://formspree.io/f/mpzkvbkg" method="POST">
                                <input className="input-contact" type="text" name="name" placeholder="Nombre"
                                       id="name"
                                       required={true}/>
                                <input type="email" name="email" placeholder="Email" id="email" required={true}/>
                                <input type="tel" name="tel" placeholder="Teléfono" id="tel" required={true}/>
                                <textarea name="message" placeholder="Mensaje" id="message" rows="5"
                                          required={true}/>
                                <button type="submit">Enviar</button>
                            </form>
                        </article>
                        <article>
                            <div
                                style={{backgroundColor: '#ffffff', padding: 5, marginBottom: 10}}>
                                <dl style={{color: '#000000'}}>
                                    <dd style={{margin: 0, textAlign: 'center'}}>Av de las palmas 735, Lomas de
                                        Chapultepec V Sección, 11000,
                                        piso 14, Ofna: 1406. Miguel Hidalgo, CDMX.
                                    </dd>
                                </dl>
                                <dl style={{color: '#000000'}}>
                                    <dd style={{margin: 0, textAlign: 'center'}}>admin@zion-tc.com</dd>
                                    <dd style={{margin: 0, textAlign: 'center'}}>+52 (55) 58337830</dd>
                                    <dd style={{margin: 0, textAlign: 'center'}}>Horario 9:00–18:00 HRS</dd>
                                </dl>
                            </div>
                            <div>
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3762.5958564884054!2d-99.21741938565722!3d19.42986024580427!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d202208676314b%3A0x17c40437e9369889!2sAv.%20Paseo%20de%20las%20Palmas%20735%2C%20Lomas%20de%20Chapultepec%2C%20Miguel%20Hidalgo%2C%2011000%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1ses-419!2smx!4v1636851421942!5m2!1ses-419!2smx"
                                    className={{border: 0}}
                                    width="100%" height="300px" frameBorder="0" allowFullScreen=""
                                    aria-hidden="false" tabIndex="0">
                                </iframe>
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!4v1637218803022!6m8!1m7!1shApAMVfiNb9-WI67I69cJA!2m2!1d19.42959156722609!2d-99.21505046151117!3f326.71511423065044!4f24.102352482275705!5f0.7820865974627469"
                                    className={{border: 0}}
                                    width="100%" height="300px" frameBorder="0" allowFullScreen=""
                                    aria-hidden="false" tabIndex="0" loading="lazy">
                                </iframe>
                            </div>
                        </article>
                    </section>
                </div>
            </div>
        </Layout>
    )
}
